import { css as original } from '@custom/defaults/theme/Components/Footer/styles';
import { merge } from 'lodash';
import { colors } from '../../Styles';

export const css = merge(original, {
  button: {
    '&.ant-btn.ant-btn-primary:not([disabled])': {
      background: '#EE3131',
      borderColor: '#EE3131',
    },
    '&.ant-btn.ant-btn-primary:not([disabled]):hover': {
      background: '#EE7373',
      borderColor: '#EE3131',
    },
    '&.ant-btn': {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        marginRight: '0.5rem',
      },
    },
  },
});

export default css;
