// customer demo

export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';

export const TENANT_DOMAIN = process.env.NEXT_PUBLIC_TENANT_DOMAIN;

export const SMS_SEND_DELAY = 60000;
export const STATUS_CHECK_INTERVAL = 20000;
export const REFRESH_DATA_INTERVAL = 60000;

export const DADATA_TOKEN = '2555eaf6f5f30697b3ca6fdd5d2a591bdb6b2b4c';
export const LOCALE = 'ru';
export const PHONE_MASK = '+7';
export const CURRENCY = 'RUR';

export const HEADER_INVERTED = true;
export const MAX_DOWNLOAD_FILE_SIZE = 5; // Megabytes
export const MIN_FIAS_LVL = 7;

export const OPTIONS = {};

export const WDYR = false;

export const SAVE_COOKIES = ['utm_source', 'click_id', 'wm_id'];
export const SAVE_COOKIES_MAP = {
  utm_source: 'UTM_SOURCE',
  click_id: 'CLICK_ID',
  wm_id: 'WM_ID',
};
export const SAVE_COOKIES_TTL = 60; //days

export const INTEGRATIONS = {};

export const SENTRY_ENVIRONMENT = NODE_ENV_PRODUCTION ? 'production' : 'local';
export const SENTRY_DSN = 'https://d82941e8749c4c819acc30be6213c92a@sentry-ru.brainysoft.ru/5';
export const SENTRY_TRACE_SAMPLE_RATE = 0.1;

export const DENY_CLICKTRACKER_LINK = null;
