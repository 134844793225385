import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/Footer/styles';

export const css = merge(original, {
  footerWrapper: {
    background: '#242424',
  },
  text: {
    color: '#bbbbbb',
    margin: '0 0 0.5rem 0',
    fontSize: '0.85rem',
  },
  email: {
    marginTop: '1rem',
    '&:before': {
      content: '"🖂"',
      display: 'inline-block',
      marginRight: '0.5rem',
      fontWeight: 'bold',
    },
  },
});

export default css;
