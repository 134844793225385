export { css as Footer } from './Footer/styles';
export { css as Button } from './Button/styles';

export {
  ActiveCreditPage,
  AuthPage,
  Avatar,
  Card,
  //Checkmark,
  ClientDataUpdatePage,
  Collapse,
  ContractInfo,
  ContractPage,
  ContractsPage,
  CreditChart,
  CreditProducts,
  CreditTable,
  CurrentStatus,
  Disbursement,
  DisclaimerWidget,
  Documents,
  DocumentsPage,
  // Footer,
  Header,
  HeaderAuth,
  HeaderRegistration,
  HeaderPhone,
  Icon,
  Icons,
  IndexPage,
  LandingPage,
  Layout,
  LoadingSpinner,
  LoanAppCreatePage,
  LoanAppCreateWidget,
  LoanAppListPage,
  LoanAppPage,
  Logo,
  MainMenu,
  MainMenuItem,
  Message,
  MessageWidget,
  Modal,
  MoneyMethod,
  Pagination,
  //PaymentsBlock,
  PaymentsPage,
  PaymentsTable,
  //PersonalData,
  PhoneInput,
  Price,
  PrintForm,
  PrintFormDocument,
  Process,
  ProfileMenu,
  ProfilePage,
  ProfileWidget,
  RegistrationPage,
  RepaymentPage,
  RepaymentWidget,
  RequestButton,
  ResultIcon,
  ScheduleTable,
  SMSProof,
  Start,
  StatefulProcess,
  Steps,
  Subdivision,
  Survey,
  Tag,
} from '@custom/defaults/theme/Components';
