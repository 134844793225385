import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import Logo from '../../../components/Logo';
import { Col, Row } from 'antd';
import React from 'react';

export const Footer: React.FC = () => {
  const classes = useThemeStyles('Footer');

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footerContainer}>
        <div className={classes.leftSide}>
          <Logo type='footer' />
        </div>
        <div className={classes.rightSide}>
          <Row>
            <Col xs={24} md={24}>
              <p className={classes.text}>
                Общество с ограниченной ответственностью Микрокредитная компания «Терция» (ООО МКК «Терция»)
              </p>
            </Col>
            <Col xs={24} md={24}>
              <p className={classes.text}>ИНН 9722023349 ОГРН 1227700340010</p>
            </Col>
            <Col xs={24} md={24}>
              <p className={classes.text}>
                Адрес: 111033, г. Москва, ул. Золоторожский Вал, дом 22, комната 1, офис 14
              </p>
            </Col>
            <Col xs={24} md={24}>
              <p className={classes.text}>режим работы пон-пятн. 9.00-18.00, сб.вс. - выходной</p>
            </Col>
            <Col xs={24} md={24}>
              <p className={classes.text}>телефон +7 (923) 480-37-77</p>
            </Col>
            <Col xs={24} md={24}>
              <p className={classes.text}>Генеральный директор Мещерякова Елена Александровна с 04.05.2023 г.</p>
            </Col>
            <Col xs={24} md={24}>
              <p className={`${classes.text} ${classes.email}`}>
                Email: <a href={'mailto: terciacredit@mail.ru'}>terciacredit@mail.ru</a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
};
